import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    if (selectedFiles.length > 0 && selectedFiles1.length) {
      Adddealer()
    } else {
      toast("Please upload image")
    }
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("firstName", form.firstName)
    dataArray.append("lastName", form.lastName)
    dataArray.append("phone", form.phone)
    dataArray.append("idNumber", form.idNumber)
    dataArray.append("idType", form.idType)
    dataArray.append("address", form.address)
    dataArray.append("pincode", form.pincode)
    dataArray.append("email", form.email)
    // dataArray.append("createdBy", datasid)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("profilePic", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("idImage", selectedFiles1[i])
    }

    axios
      .post(URLS.Addserviceman, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Servicemanlist")
            sessionStorage.setItem(
              "tost",
              "ServiceMan has been Added Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handleDrop1 = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG are allowed.")
        return
      }

      const validatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize1(file.size),
      })

      setselectedFiles([validatedFile])
    }
  }

  const formatFileSize1 = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const handleDrop2 = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG are allowed.")
        return
      }

      const validatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize2(file.size),
      })

      setselectedFiles1([validatedFile])
    }
  }

  const formatFileSize2 = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="kanavneer Franchise"
            breadcrumbItem="Add Serviceman"
          />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4 text-primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Add Serviceman
                    </h5>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter First Name"
                        required
                        value={form.firstName}
                        name="firstName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Last Name"
                        required
                        value={form.lastName}
                        name="lastName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="\d{10}"
                        value={form.phone}
                        name="phone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Id Proof Type <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.idType}
                        name="idType"
                        onChange={e => {
                          handlechange(e)
                        }}
                        id="ss"
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Aadhaar">Aadhaar</option>
                        <option value="PanCard">PanCard</option>
                        <option value="VoterId">Voter Id</option>
                      </select>
                    </div>

                    {form.idType == "Aadhaar" ? (
                      <>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Aadhaar Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Aadhaar Number"
                            minLength="12"
                            maxLength="12"
                            pattern="[0-9]{12}"
                            value={form.idNumber}
                            name="idNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {form.idType == "PanCard" ? (
                      <>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            PanCard Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter PanCard Number"
                            required
                            minLength="10"
                            maxLength="10"
                            pattern="[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"
                            value={form.idNumber}
                            name="idNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {form.idType == "VoterId" ? (
                      <>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Voter Id Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Voter Id Number"
                            required
                            value={form.idNumber}
                            name="idNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        PinCode <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter PinCode"
                        required
                        minLength="6"
                        maxLength="6"
                        pattern="\d{6}"
                        value={form.pincode}
                        name="pincode"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>
                      <div className="w-50 m-auto">
                        <Dropzone onDrop={handleDrop1}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Image.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-50 m-auto text-center mt-5">
                      <h5 style={{ fontWeight: "bold" }} className="mt-3">
                        Id Proof Image
                      </h5>
                      <div className="w-100">
                        <Dropzone onDrop={handleDrop2}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Image.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles1.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>{" "}
                    </div>{" "}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
