import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import axios from "axios"
import { URLS } from "../../Url"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Moment from "react-moment"

const Dashboard = props => {
  useEffect(() => {
    GetDashboarddata()
  }, [])

  const [Bookings, setBookings] = useState([])

  const [Topbar, setTopbar] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetDashboarddata = () => {
    var token = datas
    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookings(res.data.latestBookings)
        setTopbar(res.data)
      })
  }

  const reports = [
    {
      title: "Total Service Mans",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.totalServicemans,
      link: "/Servicemanlist",
    },
    {
      title: "Total Bookings",
      iconClass: "bx-copy-alt",
      description: Topbar.bookingCount,
      link: "/Compleatedbookings",
    },
    {
      title: "Total Services",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.totalServices,
      link: "/ResolvedComplaintbox",
    },
    {
      title: "Total Customers",
      iconClass: "bx-archive-in",
      description: Topbar.totalCustomers,
      link: "/Customerslist",
    },
  ]

  const reports1 = [
    {
      title: "Pending Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.pendingBookings,
      link: "/PendingBookings",
    },
    {
      title: "Completed Bookings",
      iconClass: "bx-copy-alt",
      description: Topbar.completedBookings,
      link: "/Compleatedbookings",
    },
    {
      title: "Pending Services",
      iconClass: "bx-purchase-tag-alt",
      description: Topbar.pendingComplaints,
      link: "/Complaintbox",
    },
    {
      title: "Completed Services",
      iconClass: "bx-archive-in",
      description: Topbar.completedComplaints,
      link: "/ResolvedComplaintbox",
    },
  ]

  const periodData = [
    {
      name: "Bookings",
      data: Topbar.monthlyBookings,
    },
    {
      name: "Total Amount ",
      data: Topbar.monthlyAmount,
    },
  ]

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
      },
    },
    colors: ["#1c77b9", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("ise")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key} style={{ fontSize: "11px" }}>
                    <Link to={report.link}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}
                {reports1.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Link to={report.link}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p
                                className="text-muted fw-medium"
                                style={{ fontSize: "11px" }}
                              >
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Sales</h4>
                  </div>

                  <ReactApexChart
                    options={options}
                    series={[...periodData]}
                    type="bar"
                    height="359"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg="12 ">
              <Card>
                <CardBody>
                  <div className="table-rep-plugin mt-2 table-responsive">
                    <h5>Latest Bookings</h5>
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>Sl.No</th>
                          <th>Customer Name</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Subscribe Date </th>
                          <th>Franchise Details</th>
                          <th>Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Bookings.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th>{key + 1}</th>
                            <td>{data.customerName}</td>
                            <td>{data.customerPhone}</td>
                            <td>{data.customerAddress}</td>
                            <td>
                              <Moment format="DD-MM-YYYY">
                                {data.purchaseDate}
                              </Moment>
                            </td>
                            <td>
                              {data.franchiseName} - {data.franchisePhone}
                            </td>
                            <td>
                              {data.status == "Registration Done" ? (
                                <>
                                  <span className="badge bg-warning">
                                    {data.status}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge bg-success">
                                    {data.status}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
