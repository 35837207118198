import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function ViewFranchise() {
  const [plans, setplans] = useState([])

  const [Pincode, setPincode] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getPlans()
  }, [])

  const getPlans = () => {
    const dataArray = new FormData()
    dataArray.append("_id", sessionStorage.getItem("franchid"))

    var token = datas
    axios
      .post(URLS.getFranchiseid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setplans(res.data.franchResult)
        setPincode(res.data.franchResult.pincode)
      })
  }
  const history = useHistory()
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="kanavneer Franchise" breadcrumbItem="View Dealer" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >   
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col lg={4}>
                  <h5 className="mb-4">Company Profile:</h5>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <a
                      href={URLS.Base + plans.companyLogo}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={URLS.Base + plans.companyLogo}
                        width="90%"
                        height="200px"
                      ></img>
                    </a>
                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-address-book font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Name: </h6>
                          <span className="text-muted">{plans.userName} </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-address-card font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Email:</h6>
                          <span className="text-muted">{plans.email}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-atlas font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Support Email:</h6>
                          <span className="text-muted">
                            {plans.supportEmail}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-chalkboard-teacher font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Phone:</h6>
                          {plans.phone}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-calendar-alt font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Date Of Joning:</h6>
                          {plans.logCreatedDate}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex mt-4">
                        <i className="fas fa-book-open font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Gst Number :</h6>
                          {plans.companyGstNumber}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex mt-4">
                        <i className="bx bx-bookmark font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Status :</h6>
                          <span className="badge bg-primary">
                            {plans.status}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg={8}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Company Name</h6>
                            <p className="text-muted">{plans.companyName}</p>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Company Phone</h6>
                            <p className="text-muted">{plans.companyPhone}</p>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 mb-1">Booking Email</h6>
                            <p className="text-muted">{plans.bookingEmail}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 mb-1">District</h6>
                            <p className="text-muted">{plans.districtName}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 mb-1">City</h6>
                            <p className="text-muted">{plans.cityName}</p>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 mb-1">Address</h6>
                            <p className="text-muted">{plans.address}</p>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 mb-1">PinCode</h6>
                            {Pincode.map((data, key) => (
                              <p className="text-muted pt-2" key={key}>
                                {data.label},
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h6 className="font-size-14 ">Company Id Image </h6>
                            <p className="text-muted">
                              <a
                                href={URLS.Base + plans.companyIdImage}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={URLS.Base + plans.companyIdImage}
                                  width="100px"
                                ></img>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFranchise
