import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Modal,
  Form,
  Label,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import { useHistory } from "react-router-dom"
import Moment from "react-moment"

const ResponsiveTables = () => {
  const [form1, setform1] = useState([])
  const [plans, setplans] = useState([])

  const [form2, setform2] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [modal_small, setmodal_small] = useState(false)

  const [modal_small1, setmodal_small1] = useState(false)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetacceptedBooking,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.acceptedBookings)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.GetacceptedSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.acceptedBookings)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    getPlans()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const history = useHistory()

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0")
  const hours = String(currentDate.getHours()).padStart(2, "0")
  const minutes = String(currentDate.getMinutes()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}`
  const formattedTime = `${hours}:${minutes}`

  console.log(formattedDate)
  console.log(formattedTime)

  const EditBanner = () => {
    var token = datas

    const dataArray = {
      planPurchaseId: form1._id,
      status: form1.status,
      "serviceTrack.machineDispatched": "active",
      "trackTimings.dispatchDate": formattedDate,
      "trackTimings.dispatchTime": formattedTime,
    }

    axios
      .post(URLS.UpdateBookingStatus, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Inprogessbookings")
            getPlans()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    EditBanner2()
  }

  const EditBanner2 = () => {
    var token = datas

    const dataArray = {
      planPurchaseId: form2._id,
      servicemanId: form2.servicemanId,
    }

    axios
      .post(URLS.AssignServicemanBookings, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const viewid = data => {
    history.push(
      "/ViewRegistationsBokings",
      sessionStorage.setItem("RegisterId", data._id)
    )
  }

  const [ServiceMan, setServiceMan] = useState([])

  useEffect(() => {
    getServiceMan()
  }, [])

  const getServiceMan = () => {
    var token = datas
    axios
      .post(
        URLS.GetServiceman,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setServiceMan(res.data.serviceResult)
      })
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="kanavneer Franchise"
                breadcrumbItem="Accepted Booking List"
              />
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={planssearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>Sl.No</th>
                              <th>Customer Name</th>
                              <th>Phone</th>
                              <th>Address</th>
                              <th>Subscribe Date </th>
                              <th>Service Man Details</th>
                              <th>Status </th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th> {(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td>{data.customerAddres}</td>
                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.purchaseDate}
                                  </Moment>
                                </td>
                                <td>
                                  {data.serviceManFirstName}
                                  {data.serviceManLastName} -{" "}
                                  {data.serviceManPhone}
                                </td>
                                <td>
                                  <span className="badge bg-success">
                                    Assigned Booking
                                  </span>
                                </td>
                                <td style={{ width: "300px" }}>
                                  <Button
                                    onClick={() => {
                                      getpopup(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="success"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bxs-user-detail"></i>
                                      <small>Machine Dispatched</small>
                                    </div>
                                  </Button>
                                  {/* <Button
                                    onClick={() => {
                                      getpopup1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="warning"
                                  >
                                    <div className="d-flex">
                                      <i className="bx bx-transfer-alt"></i>
                                      <small>ReAssing</small>
                                    </div>
                                  </Button> */}
                                  <Button
                                    onClick={() => {
                                      viewid(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    color="secondary"
                                  >
                                    <div className="d-flex">
                                      <i className="fas fa-eye"></i>
                                      <small>View</small>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Modal
                size="md"
                isOpen={modal_small}
                toggle={() => {
                  tog_small()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Machine Dispatch
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form
                    onSubmit={e => {
                      handleSubmit1(e)
                    }}
                  >
                    <Col md={12}>
                      <div className="mb-3">
                        <Label> Status Update</Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={form1.status}
                          name="status"
                          onChange={e => {
                            handleChange1(e)
                          }}
                          className="form-select"
                          required
                        >
                          <option value="">Select</option>
                          <option value="Machine Dispatched">
                            Machine Dispatched
                          </option>
                        </select>
                      </div>
                    </Col>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          setmodal_small(false)
                        }}
                        color="danger"
                        type="button"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>

              <Modal
                size="md"
                isOpen={modal_small1}
                toggle={() => {
                  tog_small1()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Assigning Service Man
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form
                    onSubmit={e => {
                      handleSubmit2(e)
                    }}
                  >
                    <Col md={12}>
                      <div className="mb-3">
                        <Label> Service Man</Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={form1.servicemanId}
                          name="servicemanId"
                          onChange={e => {
                            handleChange2(e)
                          }}
                          className="form-select"
                          required
                        >
                          <option value="">Select</option>
                          {ServiceMan.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.firstName} {data.lastName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          setmodal_small1(false)
                        }}
                        color="danger"
                        type="button"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>
              <ToastContainer />
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default ResponsiveTables
