import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next"
import { onMessage, getMessaging } from "firebase/messaging"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyD2fsoPgW3LLEGpyOBMRTk6bBlFXz8qsfg",
  authDomain: "kanavneer-99e6a.firebaseapp.com",
  projectId: "kanavneer-99e6a",
  storageBucket: "kanavneer-99e6a.appspot.com",
  messagingSenderId: "356596723723",
  appId: "1:356596723723:web:aa3e4abac5eaa90899970e",
  measurementId: "G-R4VGEP84TQ",
}

const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app)

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [message, setMessage] = useState(null)

  useEffect(() => {
    // Handle incoming messages
    const unsubscribe = onMessage(messaging, payload => {
      console.log("Message received. ", payload)
      showLocalNotification(payload)
      setMessage(payload.notification)
    })
    // Cleanup on unmount
    return () => {
      unsubscribe()
    }
  }, [])

  const showLocalNotification = payload => {
    const { title, body, icon } = payload.notification
    new Notification(title, {
      body,
      icon,
    })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
         {message && (<>
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {message && (
            <>
              {" "}
              <span className="badge bg-danger rounded-pill">
               1
              </span>
            </>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              
            </Row>
          </div>
          <SimpleBar style={{ height: "100px" }}>
            <hr></hr>
            <div className="text-reset notification-item">
              <div className="d-flex">
                {message && (
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{message.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{message.body}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/Notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu></> )}
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
