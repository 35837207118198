import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { ToastContainer } from "react-toastify"
import { URLS } from "../../Url"

function TermsConditions() {
  const [form, setform] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas
    const dataArray = new FormData()

    axios
      .post(URLS.GetSetting, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.setting.vendorTermsAndCondition)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="kanavneer Franchise"
                breadcrumbItem="Terms & Conditions"
              />
              <Row>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white"></CardHeader>

                    <CardBody>
                      <div>
                        <div>
                          <h5>Terms & Conditions</h5>
                          <div
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: form,
                            }}
                          ></div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer />
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default TermsConditions
