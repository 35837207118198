const Url = "https://api.kanavneer.com/"

export const URLS = {
  Base: Url,

  forget: Url + "v1/kanavneelapi/vendor/auth/sendotpemail",
  OTP: Url + "v1/kanavneelapi/vendor/auth/compareOtp",
  Resetpass: Url + "v1/kanavneelapi/vendor/auth/resetpassword",

  //profile
  getProfile: Url + "v1/kanavneelapi/vendor/auth/getprofile",
  UpdateProfile: Url + "v1/kanavneelapi/vendor/auth/editprofile",
  UpdateImage: Url + "v1/kanavneelapi/vendor/auth/editcompanylogo",
  ChangePass: Url + "v1/kanavneelapi/vendor/auth/changepassword",

  //serviceman
  Addserviceman: Url + "v1/kanavneelapi/vendor/serviceman/addsrvman",
  Getserviceman: Url + "v1/kanavneelapi/vendor/serviceman/getall",
  Updateserviceman: Url + "v1/kanavneelapi/vendor/serviceman/edit/",
  Deleteserviceman: Url + "v1/kanavneelapi/vendor/serviceman/updatestatus/",
  getservicemanid: Url + "v1/kanavneelapi/vendor/serviceman/getdetails",
  getservicemanidsearch:
    Url + "v1/kanavneelapi/vendor/serviceman/getall?searchQuery=",

  //States
  GetActiveStates: Url + "v1/kanavneelapi/admin/state/getallfordropdown",
  GetActiveDistrict: Url + "v1/kanavneelapi/admin/district/getallbystateid",
  GetActiveCity: Url + "v1/kanavneelapi/admin/city/getallbydistid",

  //Customers
  AddCustomers: Url + "v1/kanavneelapi/vendor/customer/addcustomer",
  GetCustomers: Url + "v1/kanavneelapi/vendor/customer/getall",
  UpdateCustomers: Url + "v1/kanavneelapi/vendor/customer/edit/",
  DeleteCustomers: Url + "v1/kanavneelapi/vendor/customer/delete/",
  getCustomersid: Url + "v1/kanavneelapi/vendor/customer/getdetails",
  getCustomersidsearch:
    Url + "v1/kanavneelapi/vendor/customer/getall?searchQuery=",

  GetPincode: Url + "v1/kanavneelapi/admin/pincode/getall",

  //Plans
  AddFranchise: Url + "v1/kanavneelapi/vendor/dealer/adddealer",
  GetFranchise: Url + "v1/kanavneelapi/vendor/dealer/getall",
  UpdateFranchise: Url + "v1/kanavneelapi/vendor/dealer/editdealer/",
  DeleteFranchise: Url + "v1/kanavneelapi/vendor/dealer/editdealerstatus/",
  getFranchiseid: Url + "v1/kanavneelapi/vendor/dealer/getdetails",
  getFranchiseidsearch:
    Url + "v1/kanavneelapi/vendor/dealer/getall?searchQuery=",

  //serviceman
  GetServiceman: Url + "v1/kanavneelapi/vendor/serviceman/getall",
  GetServicemanSearch:
    Url + "v1/kanavneelapi/vendor/serviceman/getall?searchQuery=",

  //CompleteBox
  GetPendingCompleteBox:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallpending",
  GetPendingCompleteBoxSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallpending?searchQuery=",

  GetAcceptedCompleteBox:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallaccepted",
  GetAcceptedCompleteBoxSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallaccepted?searchQuery=",

  GetInProgressCompleteBox:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallinprogress",
  GetInProgressCompleteBoxSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallinprogress?searchQuery=",

  GetCompletedCompleteBox:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallcompleted",
  GetCompletedCompleteBoxSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallcompleted?searchQuery=",

  UpdateCompleteBox: Url + "v1/kanavneelapi/vendor/complaint/updatestatus/",

  //Bookings
  GetpendingBooking:
    Url + "v1/kanavneelapi/vendor/planpurchase/getallpendingbookings",
  GetpendingBookingSearch:
    Url +
    "v1/kanavneelapi/vendor/planpurchase/getallpendingbookings?searchQuery=",

  GetacceptedBooking:
    Url + "v1/kanavneelapi/vendor/planpurchase/getallacceptedbookings",
  GetacceptedSearch:
    Url +
    "v1/kanavneelapi/vendor/planpurchase/getallacceptedbookings?searchQuery=",

  Getgetallassigned: Url + "v1/kanavneelapi/vendor/booking/getallassigned",
  GetgetallassignedSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallassigned?searchQuery=",

  GetgetallinprogressBooking:
    Url + "v1/kanavneelapi/vendor/planpurchase/getallinprogressbookings",
  GetgetallinprogressBookingSearch:
    Url +
    "v1/kanavneelapi/vendor/planpurchase/getallinprogressbookings?searchQuery=",

  Getgetallcompleted:
    Url + "v1/kanavneelapi/vendor/planpurchase/getallcompletedbookings",
  GetgetallcompletedSearch:
    Url +
    "v1/kanavneelapi/vendor/planpurchase/getallcompletedbookings?searchQuery=",

  GetcanceledBooking: Url + "v1/kanavneelapi/vendor/booking/getallcanceled",
  GetcanceledBookingSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallcanceled?searchQuery=",

  //Services
  GetcompletedServices: Url + "v1/kanavneelapi/vendor/service/getallcompleted",
  GetcompletedServicesSearch:
    Url + "v1/kanavneelapi/vendor/service/getallcompleted?searchQuery=",

  GetpendingServices: Url + "v1/kanavneelapi/vendor/service/getallpending",
  GetpendingServicesSearch:
    Url + "v1/kanavneelapi/vendor/service/getallpending?searchQuery=",

  //Complaints
  Getcomplaint: Url + "v1/kanavneelapi/vendor/complaintbox/getall",
  GetcomplaintSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getall?searchQuery=",
  Getcomplaintid: Url + "v1/kanavneelapi/vendor/complaintbox/getdetails",

  GetFAQ: Url + "v1/kanavneelapi/vendor/faq/getall",
  GetSetting: Url + "v1/kanavneelapi/vendor/setting/getsetting",

  //Reports
  servicemanReport: Url + "v1/kanavneelapi/vendor/report/servicemanreport",
  customerReport: Url + "v1/kanavneelapi/vendor/report/customerreport",
  franchiseReport: Url + "v1/kanavneelapi/vendor/report/franchisereport",
  serviceReport: Url + "v1/kanavneelapi/vendor/report/servicemanreport",
  BookingReport: Url + "v1/kanavneelapi/vendor/report/bookingreport",
  ExpireReport: Url + "v1/kanavneelapi/vendor/report/bookingexpiringreport",
  ExpiredReport: Url + "v1/kanavneelapi/vendor/report/bookingexpiredreport",
  PaymentesReport: Url + "v1/kanavneelapi/vendor/report/paymentReport",

  //Dashboard
  GetDashboard: Url + "v1/kanavneelapi/vendor/auth/getdashboard",

  UpdateBookingStatus: Url + "v1/kanavneelapi/admin/booking/updateStatusOrder",
  AssignServicemanBookings:
    Url + "v1/kanavneelapi/admin/booking/assignServicemanToBooking",

  ViewBookingId: Url + "v1/kanavneelapi/admin/booking/getplanpurchasebyid",

  AssignServicemanService:
    Url + "v1/kanavneelapi/admin/assignservicemantocomplaint",

  ViewBookingId: Url + "v1/kanavneelapi/admin/booking/getplanpurchasebyid",

  ViewServiceBookingId:
    Url + "v1/kanavneelapi/admin/complaint/getcomplaintbyid",

  //Subscription List
  SubscriptionList:
    Url + "v1/kanavneelapi/vendor/subscription/getUserPlanDetails",
  SubscriptionListSearch:
    Url + "v1/kanavneelapi/vendor/subscription/getUserPlanDetails?searchQuery=",
  GetBuySubscribeId:
    Url + "v1/kanavneelapi/vendor/subscription/getUserPlanDetailsById",

  GetCancelledBookings: Url + "v1/kanavneelapi/vendor/booking/getallcanceled",
  GetCancelledBookingsSearch:
    Url + "v1/kanavneelapi/vendor/booking/getallcanceled?searchQuery=",

  GetCancelledServices:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallrejected",
  GetCancelledServicesSearch:
    Url + "v1/kanavneelapi/vendor/complaintbox/getallrejected?searchQuery=",

  GetCountList: Url + "v1/kanavneelapi/admin/complaint/getCountVendor",
}
