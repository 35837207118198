import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { URLS } from "../../Url"
import axios from "axios"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [form, setform] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  const GetSettings = () => {
    var token = datas

    axios
      .post(
        URLS.GetCountList,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res?.data)
      })
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Vendor Menu")} </li>

            <li>
              <Link to="/dashboard">
                <i className="fas fa-home"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-users-cog"></i>
                <span>{props.t("Serviceman")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/AddServiceman">{props.t("Add Serviceman")}</Link>
                </li>
                <li>
                  <Link to="/Servicemanlist">
                    {props.t("Servicemans List ")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/Customerslist">
                <i className="fas fa-user"></i>
                <span>{props.t("Customers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-collection"></i>
                {form.pendingBookings != 0 ? (
                  <span className="badge rounded-pill bg-danger float-end">
                    {form.pendingBookings}
                  </span>
                ) : (
                  ""
                )}
                <span>{props.t("Booking List")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/PendingBookings">
                    {props.t("New Bookings")}{" "}
                    {form.pendingBookings != 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {form.pendingBookings}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/Acceptedbookings">
                    {props.t("Assigned  bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/Inprogessbookings">
                    {props.t("InProgress bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/Compleatedbookings">
                    {props.t("Completed bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/Cancelledbookings">
                    {props.t("Cancelled bookings")}
                    {form.cancelledBookings != 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {form.cancelledBookings}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-food-menu"></i>
                <span>{props.t("Subscribers List")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/LaunchingPlanSubscription">
                    {props.t("Launching Plans")}
                  </Link>
                </li>
                <li>
                  <Link to="/ExpireLaunchingPlanSubscription">
                    {props.t("Expired Launching Plans")}
                  </Link>
                </li>
                <li>
                  <Link to="/RegularPlanSubscription">
                    {props.t("Regular Plans")}
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/ExpireRegularPlan">
                    {props.t("Expired Regular Plans")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-box-open"></i>
                {form.pendingServices != 0 ? (
                  <span className="badge rounded-pill bg-danger float-end">
                    {form.pendingServices}
                  </span>
                ) : (
                  ""
                )}
                <span>{props.t("Services")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Complaintbox">
                    {" "}
                    {props.t("New Services")}
                    {form.pendingServices != 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {form.pendingServices}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/AssignedComplaintbox">
                    {props.t("Assigned Services")}
                  </Link>
                </li>
                <li>
                  <Link to="/InProgessComplaintbox">
                    {props.t("InProgress Services")}
                  </Link>
                </li>
                <li>
                  <Link to="/ResolvedComplaintbox">
                    {props.t("Completed Services")}
                  </Link>
                </li>
                <li>
                  <Link to="/CancellationService">
                    {props.t("Cancelled Service")}
                    {form.cancelledServices != 0 ? (
                      <span className="badge rounded-pill bg-danger float-end">
                        {form.cancelledServices}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-file-alt"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ServicemanwiseReport">
                    {props.t("Service Man Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/Customerwisereport">
                    {props.t("Customers Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/Bookingreport">{props.t("Booking Report")}</Link>
                </li>
                <li>
                  <Link to="/PaymentReport">{props.t("Payment Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-cogs"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/profile">{props.t("Profile")}</Link>
                </li>
                <li>
                  <Link to="/Faqs">{props.t("Faqs")}</Link>
                </li>
                <li>
                  <Link to="/About">{props.t("About")}</Link>
                </li>
                <li>
                  <Link to="/RefundPolicy">{props.t("Refund Policy")}</Link>
                </li>
                <li>
                  <Link to="/PrivacyPolicy">{props.t("Privacy Policy")}</Link>
                </li>
                <li>
                  <Link to="/Terms">{props.t("Terms & Conditions")}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
