import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import trash from "../../assets/images/trash.gif"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

const ResponsiveTables = () => {
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)
  const toggle2 = () => setshow2(!show2)

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const [plans, setplans] = useState([])
  const history = useHistory()
  
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetFranchise,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.dealerResult)
      })
  }

  const plansid = data => {
    sessionStorage.setItem("planid", data._id)
    history.push("/EditDealer")
  }

  const datapass = data => {
    setform2(data)
    setshow1(true)
  }

  const getPlandelete = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeleteFranchise + params,
        { status: "inactive" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datapass1 = data => {
    setform2(data)
    setshow2(true)
  }

  const getPlandelete1 = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeleteFranchise + params,
        { status: "active" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.getFranchiseidsearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.dealerResult)
      })
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const viewid = data => {
    history.push("/ViewDealer", sessionStorage.setItem("franchid", data._id))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="kanavneer Franchise" breadcrumbItem="Dealer list" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Link to="/AddDealer">
                        <Button color="primary">
                          Add Dealer <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={planssearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Company Logo </th>
                          <th>Company Name </th>
                          <th>Company Phone</th>
                          <th>Booking Email</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th> {(pageNumber - 1) * 20 + key + 21}</th>
                            <td>
                              <img
                                src={URLS.Base + data.companyLogo}
                                width="50px"
                              ></img>
                            </td>
                            <td>{data.companyName}</td>
                            <td>{data.companyPhone}</td>
                            <td>{data.bookingEmail}</td>
                            <td>
                              {data.status == "active" ? (
                                <span className="badge bg-primary">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  {data.status}
                                </span>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  viewid(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="info"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i>
                              </Button>
                              <Button
                                onClick={() => {
                                  plansid(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>

                              {data.status === "active" ? (
                                <Button
                                  onClick={() => {
                                    datapass(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="danger"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="bx bxs-notification-off"
                                  ></i>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    datapass1(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="info"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="bx bxs-check-square"
                                  ></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
      <Modal size="sm" isOpen={show1} toggle={toggle} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want InActive</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="sm" isOpen={show2} toggle={toggle2} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want Active</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete1()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle2}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ResponsiveTables
