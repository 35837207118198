import React, { useEffect, useState } from "react"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { Container } from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"

const firebaseConfig = {
  apiKey: "AIzaSyD2fsoPgW3LLEGpyOBMRTk6bBlFXz8qsfg",
  authDomain: "kanavneer-99e6a.firebaseapp.com",
  projectId: "kanavneer-99e6a",
  storageBucket: "kanavneer-99e6a.appspot.com",
  messagingSenderId: "356596723723",
  appId: "1:356596723723:web:aa3e4abac5eaa90899970e",
  measurementId: "G-R4VGEP84TQ",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

const App = () => {
  const [token, setToken] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BAZrYLilSMP32ZLcGYlg4C-DCuh1v4_d_sCuJFC1u2dieXVOCxzE-ZhUpaVuqoyn_xBXYXnoXsIQpQKd6qrhdpI",
        })
        if (currentToken) {
          console.log("Current token for client: ", currentToken)
          setToken(currentToken)
          // Perform any other necessary logic like storing the token
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          )
        }
      } catch (err) {
        console.log("An error occurred while retrieving token. ", err)
      }
    }

    fetchToken()

    const unsubscribe = onMessage(messaging, payload => {
      console.log("Message received. ", payload)
      setMessage(payload.notification)
    })

    // Cleanup on unmount
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kanavneer Admin"
            breadcrumbItem="Firebase Notifications"
          />
          <h1>Firebase Notifications</h1>
          {token && (
            <p>
              <strong>Token:</strong> {token}
            </p>
          )}
          {message && (
            <div>
              <h2>New Notification</h2>
              <p>
                <strong>Title:</strong> {message.title}
              </p>
              <p>
                <strong>Body:</strong> {message.body}
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default App
