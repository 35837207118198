import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { URLS } from "../../Url"

const Banner = () => {
  const [banner, setbanner] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    getAllbenners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getAllbenners = () => {
    var token = datas
    axios
      .post(
        URLS.GetFAQ,
        { userType: "vendor" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setbanner(res.data.faqs)

          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-5 pt-5"
          >
            <Spinner
              style={{ marginTop: "250px" }}
              className="ms-2"
              animation="border"
              color="primary"
              size="lg"
            />{" "}
            <div>Loading......</div>
          </div>
        </>
      ) : (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="kanavneer Franchise" breadcrumbItem="FAQ" />
              <Row>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>FAQ List</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div>
                        {banner.map((data, key) => (
                          <div className="faq-box d-flex mb-4" key={key}>
                            <div className="flex-shrink-0 me-3 faq-icon">
                              <i className="bx bx-help-circle font-size-20 text-info" />
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="font-size-15">{data.question}</h5>
                              <p className="text-muted">{data.answer}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer />
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default Banner
