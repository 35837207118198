import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"
import Select from "react-select"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var userid = data.user._id


  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const history = useHistory()

  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("franchiseObjId", userid)
    dataArray.append("companyName", form.companyName)
    dataArray.append("companyPhone", form.companyPhone)
    dataArray.append("companyGstNumber", form.companyGstNumber)
    dataArray.append("address", form.address)
    dataArray.append("supportEmail", form.supportEmail)
    dataArray.append("bookingEmail", form.bookingEmail)
    dataArray.append("userName", form.userName)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("phone", form.phone)
    dataArray.append("stateId", form.stateId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("cityId", form.cityId)
    dataArray.append("pincode", JSON.stringify(selectedOptions))

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("companyLogo", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("companyIdImage", selectedFiles1[i])
    }

    axios
      .post(URLS.AddFranchise, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/DealerList",
              sessionStorage.setItem(
                "tost",
                "Dealer has been Added successfully"
              )
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [states, setstates] = useState([])

  const [District, setDistrict] = useState([])

  const [City, setCity] = useState([])

  useEffect(() => {
    getallStates()
  }, [])

  const getallStates = () => {
    var token = datas
    axios
      .post(URLS.GetActiveStates, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetActiveDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetActiveCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const [Pincode, setPincode] = useState([])

  useEffect(() => {
    GetPincodes()
  }, [])

  const GetPincodes = () => {
    var token = datas
    axios
      .post(
        URLS.GetPincode,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setPincode(res.data.pincodes)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedOptions, setSelectedOptions] = useState([])

  const Categoryss = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const options = Pincode.map(response => ({
    value: response._id,
    label: response.postalCode,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="kanavneer Franchise" breadcrumbItem="Add Dealer" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Dealer Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Dealer Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Dealer Name"
                        required
                        value={form.companyName}
                        name="companyName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Dealer Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Dealer Phone"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.companyPhone}
                        name="companyPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Dealer Booking Email{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Booking Email"
                        required
                        value={form.bookingEmail}
                        name="bookingEmail"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Support Email
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Support Email"
                        value={form.supportEmail}
                        name="supportEmail"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Dealer Logo</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} required />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <h5
                    className="mb-4"
                    style={{ color: "#0e4875", fontWeight: "bold" }}
                  >
                    Address Information
                  </h5>
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select state <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.stateId}
                        name="stateId"
                        onChange={e => {
                          Optionchange(e)
                        }}
                        id="ss"
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select District <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.districtId}
                        name="districtId"
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        id="ss"
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select City <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.cityId}
                        name="cityId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        id="ss"
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md="4">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        PinCode
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={options}
                        placeholder="Enter PinCode"
                        value={selectedOptions}
                        onChange={Categoryss}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </Col>{" "}
                  <Col md="8">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="2"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Business Information
                    </h5>

                    <div className="mb-5">
                      <Label for="basicpill-firstname-input1">
                        Gst Number<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Gst Number"
                        required
                        value={form.companyGstNumber}
                        name="companyGstNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-1 mt-3">
                      <h5 style={{ fontWeight: "bold" }}>Dealer Docments</h5>
                      <div className="w-50">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles1(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} required />
                                <div className="mb-1">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles1.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>{" "}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <Row>
                      <h5
                        className="mb-2"
                        style={{ color: "#0e4875", fontWeight: "bold" }}
                      >
                        Person Information
                      </h5>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Dealer Person Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Person Name"
                            required
                            value={form.userName}
                            name="userName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Dealer Person Phone{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        value={form.phone}
                        name="phone"
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <h5
                      className="mb-2"
                      style={{ color: "#0e4875", fontWeight: "bold" }}
                    >
                      Account Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-5">
                      <Label for="basicpill-firstname-input1">
                        Password<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Password"
                        required
                        value={form.password}
                        name="password"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
