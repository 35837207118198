import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import Resetpsw from "pages/Authentication/Resetpsw"
import Compareotp from "pages/Authentication/Compareotp"


// Dashboard
import Dashboard from "../pages/Dashboard/index"
import AddServiceman from "pages/Serviceman/AddServiceman"
import Servicemanlist from "pages/Serviceman/Servicemanlist"
import Customerslist from "pages/Customers/Customerslist"
import AddCustomer from "pages/Customers/AddCustomer"

import Faqs from "pages/Settings/Faqs"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Terms from "pages/Settings/Terms"
import About from "pages/Settings/About"
import PrivacyPolicy from "pages/Settings/PrivacyPolicy"

import EditCustomer from "pages/Customers/EditCustomer"

import EditServiceman from "pages/Serviceman/EditServiceman"

//Bookings
import BookingDetails from "pages/Bookinglist/BookingDetails"
import PendingBookings from "pages/Bookinglist/PendingBookings"
import Acceptedbookings from "pages/Bookinglist/Acceptedbookings"
import Compleatedbookings from "pages/Bookinglist/Compleatedbookings"
import Inprogessbookings from "pages/Bookinglist/Inprogessbookings"
import Assignbookings from "pages/Bookinglist/Assignbookings"
import Cancelledbookings from "pages/Bookinglist/Cancelledbookings"

//Reports
import Customerwisereport from "pages/Reports/Customerwisereport"
import ServicemanwiseReport from "pages/Reports/ServicemanwiseReport"
import FranchisewiseReport from "pages/Reports/FranchisewiseReport"
import Bookingreport from "pages/Reports/Bookingreport"
import ServiceRepot from "pages/Reports/ServiceRepot"
import ExpiredReport from "pages/Reports/ExpiredReport"
import Expirereport from "pages/Reports/Expirereport"

import AssignedComplaintbox from "pages/AssignedComplaintbox"
import InProgessComplaintbox from "pages/InProgessComplaintbox"
import Complaintbox from "pages/Complaintbox"
import ResolvedComplaintbox from "pages/ResolvedComplaintbox"

import AddDealer from "pages/Dealer/AddDealer"
import EditDealer from "pages/Dealer/EditDealer"
import DealerList from "pages/Dealer/DealerList"
import ViewDealer from "pages/Dealer/ViewDealer"

import ViewCustomer from "pages/Customers/ViewCustomer"

import ViewServiceMan from "pages/Serviceman/ViewServiceMan"

import ViewRegistationsBokings from "pages/Bookinglist/ViewRegistationsBokings"

import ViewServices from "pages/ViewServices"

import RegularPlanSubscription from "pages/Subscription/RegularPlanSubscription"
import ExpireRegularPlan from "pages/Subscription/ExpireRegularPlan"
import LaunchingPlanSubscription from "pages/Subscription/LaunchingPlanSubscription"
import ExpireLaunchingPlanSubscription from "pages/Subscription/ExpireLaunchingPlanSubscription"
import ViewSubscription from "pages/Subscription/ViewSubscription"

import PaymentReport from "pages/Reports/PaymentsReport"

import ViewRegularSubscription from "pages/Subscription/ViewRegularSubscription"

import CancellationService from "pages/CancellationService"


import Test from "pages/Test"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/Test", component: Test },

  //Subcription
  { path: "/RegularPlanSubscription", component: RegularPlanSubscription },
  { path: "/ExpireRegularPlan", component: ExpireRegularPlan },
  { path: "/LaunchingPlanSubscription", component: LaunchingPlanSubscription },
  { path: "/ExpireLaunchingPlanSubscription", component: ExpireLaunchingPlanSubscription },
  { path: "/ViewSubscription", component: ViewSubscription },

  { path: "/ViewRegularSubscription", component: ViewRegularSubscription },

  { path: "/CancellationService", component: CancellationService },

  { path: "/PaymentReport", component: PaymentReport },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/EditServiceman", component: EditServiceman },

  { path: "/ViewCustomer", component: ViewCustomer },

  { path: "/ViewRegistationsBokings", component: ViewRegistationsBokings },

  { path: "/ViewServiceMan", component: ViewServiceMan },

  { path: "/ViewComplaintbox", component: ViewServices },

  //Dealer
  { path: "/AddDealer", component: AddDealer },
  { path: "/EditDealer", component: EditDealer },
  { path: "/DealerList", component: DealerList },
  { path: "/ViewDealer", component: ViewDealer },

  //Bookings
  { path: "/BookingDetails", component: BookingDetails },
  { path: "/PendingBookings", component: PendingBookings },
  { path: "/Acceptedbookings", component: Acceptedbookings },
  { path: "/Compleatedbookings", component: Compleatedbookings },
  { path: "/Inprogessbookings", component: Inprogessbookings },
  { path: "/Assignbookings", component: Assignbookings },
  { path: "/Cancelledbookings", component: Cancelledbookings },

  { path: "/ResolvedComplaintbox", component: ResolvedComplaintbox },
  { path: "/AssignedComplaintbox", component: AssignedComplaintbox },
  { path: "/InProgessComplaintbox", component: InProgessComplaintbox },
  { path: "/Complaintbox", component: Complaintbox },

  { path: "/EditCustomer", component: EditCustomer },


  { path: "/AddServiceman", component: AddServiceman },
  { path: "/Servicemanlist", component: Servicemanlist },
  { path: "/Customerslist", component: Customerslist },
  { path: "/AddCustomer", component: AddCustomer },


  // { path: "/TermsConditions", component: TermsConditions },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Terms", component: Terms },
  { path: "/About", component: About },
  { path: "/Faqs", component: Faqs },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },

  //Reports
  { path: "/Customerwisereport", component: Customerwisereport },
  { path: "/ServicemanwiseReport", component: ServicemanwiseReport },
  { path: "/FranchisewiseReport", component: FranchisewiseReport },
  { path: "/Bookingreport", component: Bookingreport },
  { path: "/ServiceRepot", component: ServiceRepot },
  { path: "/ExpiredReport", component: ExpiredReport },
  { path: "/Expirereport", component: Expirereport },

  // this route should be at the end of all other routesside
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Resetpsw", component: Resetpsw },
  { path: "/Compareotp", component: Compareotp },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
